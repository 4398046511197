import React, { memo } from 'react';

const Story = memo(() => {
    return (
        <div className='pos-relative'>
            {/* <Container> */}
            <div className='pos-absolute spin-animation' style={{ left: 10, top: -20, width: 45 }}>
                <img src='/images/enter/star.png' alt="star" />
            </div>
            <div className='p-t-30 pos-relative' style={{ zIndex: 2 }}>
                <img src='/images/landing/story-m.png' alt="story" className='w-full' />
            </div>
            <div className='pos-absolute spin-animation' style={{ right: -100, top: 10, width: 150 }}>
                <img src='/images/enter/firework.png' alt="star" />
            </div>
        </div>
    )
});

export default Story;