import Home from '@pages/Home';

// ONLY CAN ACCESS AFTER LOGIN
export const private_routes = [];

// CAN ACCESS BEFORE OR AFTER LOGIN
export const public_routes = [
    {
        path: "/",
        component: <Home />,
        showTutorBtn: false,
        showBackBtn : false,
        showLogo: true,
        // backTitle: 'title.home'
    },
];

// ONLY CAN ACCESS BEFORE LOGIN
export const public_restricted_routes = [];