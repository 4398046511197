import React, { memo } from 'react';
import _ from 'lodash';

// MUI
import { Container } from '@mui/material';

// SWIPER
import 'swiper/css/bundle';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const GALLERY_ONE = [
    '/images/gallery/1.png',
    '/images/gallery/1.png',
    '/images/gallery/1.png',
    '/images/gallery/1.png',
    '/images/gallery/1.png',
];

const GALLERY_TWO = [
    '/images/gallery/2.png',
    '/images/gallery/2.png',
    '/images/gallery/2.png',
    '/images/gallery/2.png',
    '/images/gallery/2.png',
];

const GALLERY_THREE = [
    '/images/gallery/3.png',
    '/images/gallery/3.png',
    '/images/gallery/3.png',
    '/images/gallery/3.png',
    '/images/gallery/3.png',
];

const GALLERY_FOUR = [
    '/images/gallery/4.png',
    '/images/gallery/4.png',
    '/images/gallery/4.png',
    '/images/gallery/4.png',
    '/images/gallery/4.png',
];

const GALLERY_FIVE = [
    '/images/gallery/5.png',
    '/images/gallery/5.png',
    '/images/gallery/5.png',
    '/images/gallery/5.png',
    '/images/gallery/5.png',
];


const Gallery = memo(() => {

    return (
        <>
            <Container maxWidth="md" className='pos-relative' style={{ zIndex: 2 }}>
                <div className='flex-sa' style={{ alignItems: 'stretch' }}>
                    <div style={{ width: '30%' }}>
                        <div className='p-b-20'>
                            <Swiper
                                speed='2500'
                                slidesPerView={1}
                                centeredSlides={true}
                                simulateTouch={false}
                                loop={true}
                                modules={[FreeMode, Autoplay]}
                                autoplay={{
                                    delay: 2525, // how much time to go next image
                                    disableOnInteraction: true, // false means let user can drag and see
                                }}
                                style={{ width: "100%" }}
                            >
                                {
                                    _.map(GALLERY_ONE, (src, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <img src={src} alt="photos" style={{ boxShadow: '5px 5px 6px 0 #0001' }} />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                        <div>
                            <Swiper
                                speed='2500'
                                slidesPerView={1}
                                centeredSlides={true}
                                simulateTouch={false}
                                loop={true}
                                modules={[FreeMode, Autoplay]}
                                autoplay={{
                                    delay: 3600,
                                    disableOnInteraction: true, // false means let user can drag and see
                                }}
                                style={{ width: "100%" }}
                            >
                                {
                                    _.map(GALLERY_TWO, (src, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <img src={src} alt="photos" style={{ boxShadow: '5px 5px 6px 0 #0001' }} />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                    <div className='flex-c-m' style={{ width: '30%' }}>
                        <Swiper
                            speed='2500'
                            slidesPerView={1}
                            centeredSlides={true}
                            simulateTouch={false}
                            loop={true}
                            modules={[FreeMode, Autoplay]}
                            autoplay={{
                                delay: 4545,
                                disableOnInteraction: true, // false means let user can drag and see
                            }}
                            style={{ width: "100%" }}
                        >
                            {
                                _.map(GALLERY_THREE, (src, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <img src={src} alt="photos" style={{ boxShadow: '5px 5px 6px 0 #0001' }} />
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                    <div style={{ width: '30%' }}>
                        <div className='p-b-20'>
                            <Swiper
                                speed='2500'
                                slidesPerView={1}
                                centeredSlides={true}
                                simulateTouch={false}
                                loop={true}
                                modules={[FreeMode, Autoplay]}
                                autoplay={{
                                    delay: 4040,
                                    disableOnInteraction: true, // false means let user can drag and see
                                }}
                                style={{ width: "100%" }}
                            >
                                {
                                    _.map(GALLERY_FOUR, (src, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <img src={src} alt="photos" style={{ boxShadow: '5px 5px 6px 0 #0001' }} />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                        <div>
                            <Swiper
                                speed='2500'
                                slidesPerView={1}
                                centeredSlides={true}
                                simulateTouch={false}
                                loop={true}
                                modules={[FreeMode, Autoplay]}
                                autoplay={{
                                    delay: 3030,
                                    disableOnInteraction: true, // false means let user can drag and see
                                }}
                                style={{ width: "100%" }}
                            >
                                {
                                    _.map(GALLERY_FIVE, (src, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <img src={src} alt="photos" style={{ boxShadow: '5px 5px 6px 0 #0001' }} />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </Container>
            <div className='pos-absolute' style={{ bottom: 0, left: 0, width: 800, maxWidth: '80%' }}>
                <img src='/images/landing/flower.png' alt="flower" />
            </div>
        </>
    )
});

export default Gallery;