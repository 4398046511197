import React, { memo, useState, useEffect } from 'react';
import {public_restricted_routes, public_routes, private_routes } from '@configs/Router';
import _ from 'lodash';
import { removeLoginAccess } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, makeStyles } from '@mui/styles';
import { IconButton, Menu, Box, MenuItem, Tooltip, Container} from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, matchRoutes, useParams } from 'react-router-dom';

import { changeLanguage } from '@actions';
import moment from 'moment-timezone';

import MenuIcon from '@mui/icons-material/Menu';


import { IoLanguage } from "react-icons/io5";
import { FiChevronLeft } from "react-icons/fi";



const systemLanguages = ['en', 'cn', 'id', 'kr', 'jp', 'th', 'vi'];

const INITIAL_STATE = { showTutorBtn: false, showBackBtn: false, backUrl: null, tutorUrl: null, showLogo: true, noHeader: false };

const TitleBar = memo((props) => {

    const theme = useTheme();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const [option, setOption] = useState(INITIAL_STATE);
    const [anchorEl, setAnchorEl] = useState(null);
    const { accessToken } = useSelector(state => state.general);
    const { email, name } = useSelector(state => state.user);
    const open = anchorEl?.attributes?.['data-ref']?.value;//Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event = null) => {
        if (event) event.stopPropagation()
        setAnchorEl(null);
    };
    let location = useLocation();
    let navigate = useNavigate();
    let from = location.state?.from?.pathname;
    let current_path = location?.pathname;
    let { id } = useParams();

    const [serverTime, setServerTime] = useState("");

    useEffect(() => {
        let public_restricted_detail = matchRoutes(public_restricted_routes, current_path)?.[0]?.['route'];
        let public_detail = matchRoutes(public_routes, current_path)?.[0]?.['route'];
        let private_detail = matchRoutes(private_routes, current_path)?.[0]?.['route'];

        if (_.size(public_restricted_detail) > 0) {
            setOption({ ...option, showBackBtn: public_restricted_detail?.showBackBtn, showTutorBtn: public_restricted_detail?.showTutorBtn, showLogo: public_restricted_detail?.showLogo, tutorUrl: public_restricted_detail?.tutorUrl, backUrl: public_restricted_detail?.backUrl, backTitle: t(public_restricted_detail?.backTitle), noHeader: public_restricted_detail?.noHeader });

        } else if (_.size(public_detail) > 0) {
            setOption({ ...option, showBackBtn: public_detail?.showBackBtn, showTutorBtn: public_detail?.showTutorBtn, showLogo: public_detail?.showLogo, tutorUrl: public_detail?.tutorUrl, backUrl: public_detail?.backUrl, backTitle: t(public_detail?.backTitle), noHeader: public_detail?.noHeader });

        } else if (_.size(private_detail) > 0) {
            setOption({ ...option, showBackBtn: private_detail?.showBackBtn, showTutorBtn: private_detail?.showTutorBtn, showLogo: private_detail?.showLogo, tutorUrl: private_detail?.tutorUrl, backUrl: private_detail?.backUrl, backTitle: t(private_detail?.backTitle), noHeader: private_detail?.noHeader });

        } else {
            setOption(INITIAL_STATE);
        }

    }, [current_path, t]);

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval(() => {
            setServerTime(moment().format("YYYY-MM-DD HH:mm:ss"))
        }, 1000)

        return () => clearInterval(secTimer);
    }, [])

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleClose();
    };

    useEffect(() => {
        if (i18n.language == "ur") {
            document.documentElement.dir = 'rtl'
        } else {
            document.documentElement.dir = 'ltr';
        }
        document.documentElement.lang = i18n.language;
        // eslint-disable-next-line
    }, [i18n.language]);

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawerWidth = 240;
    const navItems = [
        {
            title:'Home',
            link:'/',
            target:'_top',
        },
        {
            title:'Whitepaper',
            link:'https://whitepaper.aineural.app/',
            target:'_blank',
        },
        // {
        //     title:'Tokenomics',
        //     link:'/'
        // }
    ];

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <div className='p-t-20 p-b-15'>
                <img src='/images/enter/xixi.png' alt="logo" style={{ height: 40 }} />
            </div>
            {/* <Divider />
            <List>
                {navItems.map((item) => (
                    <div key={item.title} >
                            <Link to={item.link} target={item.target} underline='none' component={RouterLink} >
                                <div className='p-tb-20 txt-left p-lr-20'>
                                <p className='cl-dark'>{item.title} </p>
                                </div>
                            </Link>
                    </div>
                ))}
            </List> */}
        </Box>
    );

    return (
        <>
            {/* <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav> */}
            <div className='theme-bg' style={{height:60, top:0}}>
            <Container>
            <div className='w-full flex-sb-m  p-tb-8'>
                <div className='p-t-6'>
                    <img src='/images/design/xixi-gold.png' alt="logo" style={{ height: 46 }} />
                </div>
                <Tooltip title={t('general.language')} arrow><IconButton data-ref="language" onClick={handleClick} ><IoLanguage /></IconButton></Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={open === "language"}
                    onClose={handleClose}
                    onClick={handleClose}
                    disableScrollLock={true}
                    slotProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {
                        _.map(systemLanguages, (_languages, index) => (
                            <MenuItem key={index} onClick={() => changeSystemLanguage(_languages)} >{t(`general.${_languages}`)}</MenuItem>
                        ))
                    }
                </Menu>

                {/* <div className='cl-dark inter-normal fs-14 flex-m'>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 2, display: { sm: 'none' } }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Box className="w-full " sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    <div className='w-full flex-m'>
                                    {navItems.map((item) => (
                                        <div key={item.title} className='p-l-15'>
                                            <Link to={item.link} target={item.target} underline='none' component={RouterLink} state={{ from: storePaths(location) }}>
                                                <p className='cl-dark'>{item.title}</p>
                                            </Link>
                                        </div>
                                    ))}
                                    </div>
                                </Box>
                            </div> */}


            </div>
            </Container>
            </div>
        </>
    )
});

const useStyles = makeStyles((theme) => ({
}));

export default TitleBar;