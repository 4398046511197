import {
    CHANGE_LANGUAGE
} from '../actions/types';

const INITIAL_STATE = {
    language: process.env.REACT_APP_DEFAULT_LANGUAGE || 'cn',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case CHANGE_LANGUAGE:
            return { ...state, language: actions.payload };
        default:
            return state;
    }
}