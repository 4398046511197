import React, { memo } from 'react';

const Story = memo(() => {
    return (
        <div className='flex-sa-m pos-relative p-t-80 p-b-30'>
            <div style={{ width: '25%' }}>
                <img src='/images/landing/story1b.png' alt="story" style={{ transform: 'rotate(19.34deg)' }} />
            </div>
            <div style={{ width: '25%' }}>
                <img src='/images/landing/story2b.png' alt="story" style={{ transform: 'rotate(-12.58deg)', marginTop: -80 }} />
            </div>
            <div style={{ width: '25%' }}>
                <img src='/images/landing/story3b.png' alt="story" style={{ transform: 'rotate(10.10deg)', marginTop: -30 }} />
            </div>
            <div style={{ width: '25%' }}>
                <img src='/images/landing/story4b.png' alt="story" style={{ transform: 'rotate(-10.38deg)' }} />
            </div>
        </div>
    )
});

export default Story;