import React from 'react'; //,{ useState, useEffect, useRef, createRef } 


export default function DialogFrame({children}) {
    
    return (
        <div className='p-b-20 p-lr-20 pos-relative w-full' style={{ background: '#F7F7F7' }}>
        <div className='pos-absolute lattern-animation' style={{width:80, maxWidth:'12%'}}>
            <img src="/images/enter/lattern.png" alt="lattern" className='w-full'/>
        </div>
            <div className='flex-sb w-full p-t-20' style={{ alignItems: 'flex-start' }}>
                <div style={{ width: 150, maxWidth: '25%' }}>
                    <img src='/images/dialog/frame.png' alt="frame" />
                </div>
                <div className='p-t-30'>
                    <div className='p-b-10' style={{ width: 80, margin: '0 auto' }}>
                        <img src='/images/enter/xixi.png' alt="xixi" />
                    </div>
                    {/* <p className='fs-30' style={{ color: '#9E2F2F' }}>前宴 · 送嫁酒</p> */}
                </div>
                <div style={{ width: 150, maxWidth: '25%', transform: 'scaleX(-1)' }}>
                    <img src='/images/dialog/frame.png' alt="frame" />
                </div>
            </div>

            <div style={{ width: '80%', margin: '0 auto' }}>
                {children}
            </div>

            <div className='flex-sb-m w-full' style={{ transform: 'scaleY(-1)', marginTop:'-10%' }}>
                <div style={{ width: 150, maxWidth: '25%' }}>
                    <img src='/images/dialog/frame.png' alt="frame" />
                </div>
                <div style={{ width: 150, maxWidth: '25%', transform: 'scaleX(-1)' }}>
                    <img src='/images/dialog/frame.png' alt="frame" />
                </div>
            </div>
        </div>
    )
}


