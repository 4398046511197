import {
    STORE_PROFILE,
    RESET_PROFILE,
} from '../actions/types';

const INITIAL_STATE = {
    id: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_PROFILE:
            return {
                ...state,
                id: actions.payload.id,
            };
        case RESET_PROFILE:
            return INITIAL_STATE;
        default:
            return state;
    }
}