import PropTypes from 'prop-types';
import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { postUrl } from '@utils/ApiAction';
import { changeLanguage } from '@actions';

// MUI
import { Container, TextField, Tabs, Grid, Tab, Box, Hidden, Button, Dialog,  Slide, FormControl, Select, MenuItem } from '@mui/material';

// ICON
import { FaMapMarkerAlt, FaLink, FaPlay, FaPause } from "react-icons/fa";
import { IoQrCode } from "react-icons/io5";
// import { FaWaze } from "react-icons/fa6";
import { FiX } from "react-icons/fi";
import { MdAccessTime } from "react-icons/md";
import { LuCalendarCheck } from "react-icons/lu";
import { MdSettings } from "react-icons/md";


//LAYOUT & COMPONENTS
import StoryMobile from '@pages/Mobile/Story';
import StoryDesktop from '@pages/Desktop/Story';
import DialogFrame from '@layouts/DialogFrame';
import GalleryMobile from '@pages/Mobile/Gallery';
// import TitleBar from '@layouts/TitleBar';

import 'swiper/css/bundle';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function calculateTimeLeft() {
    const difference = new Date('2024-09-14 19:00:00') - new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

const FloatingButton = ({ onClick, children }) => {
    const style = {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#AF3535',
        color: '#F5CB7B',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        border: 'none',
        cursor: 'pointer',
        zIndex: 1000,
    };

    return (
        <button style={style} onClick={onClick}>
            {children}
        </button>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Home = memo(() => {
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    // const [walletTooltip, setWalletTooltip] = useState({});
    const [timeLeft, setTimeLeft] = useState({
        days: '',
        hours: '',
        minutes: '',
        seconds: '',
    });
    const [audio] = useState(() => new Audio("/music/music3b.mp3"));
    const [musicPlay, setMusicPlay] = useState(false);
    const [enter, setEnter] = useState(false);
    const [hideDoor, setHideDoor] = useState(false);
    const [state, setState] = useState({
        name: '',
        pax: '',
        mobile: '',
        relationship: '',
        relationship_to: '',
        remark: ''
    })
    const [inputErrors, setInputErrors] = useState({});

    const RELATIONSHIP_TO = {
        'bride': t('general.bride'),
        'groom': t('general.groom'),
    }

    const RELATIONSHIP = {
        'family': t('rsvp.family'),
        'relative': t('rsvp.relative'),
        'friend': t('rsvp.friend'),
        'colleague': t('rsvp.colleague')
    }

    // music 
    useEffect(() => {
        audio.loop = true;
        if (musicPlay) {
            audio.play();
        } else {
            audio.pause();
        }
        // eslint-disable-next-line
    }, [musicPlay])

    useEffect(() => {
        if (enter) {
            const hideDoorTimer = setInterval(() => {
                setHideDoor(true);
            }, 1500);

            return () => clearInterval(hideDoorTimer);
        }
    }, [enter])

    useEffect(() => {
        const countdownTimer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(countdownTimer);
    }, []);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile' || name === 'pax') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        postUrl('/rsvps', state).then(response => {
            if (response.status) {
                setState({ ...state, name: '', pax: '', mobile: '', relationship: '', relationship_to: '', remark: '' });
                addAlert('', t('success.submitSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.submitError'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.systemError'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    //LANGUAGE
    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        setInputErrors([]);
    };

    //TAB
    const [value, setValue] = React.useState(0);
    const handleChangeNavigate = (event, newValue) => {
        setValue(newValue);
    };

    //SETTING BUTTON
    const [isVisible, setIsVisible] = useState(false);

    const handleToggle = () => {
        setIsVisible(!isVisible);
    };

    //DIALOG - DINNER
    const [dinnerDialog, setDinnerDialog] = React.useState(false);


    //DIALOG - HOUSE
    const [houseDialog, setHouseDialog] = React.useState({
        open: false,
        data: {
            title: '',
            date: '',
            time: '',
        }
    });
    const openHouseDialog = (title, date, time) => {
        setHouseDialog({ ...houseDialog, open: true, data: { title: title, date: date, time: time } });
    };
    const closeHouseDialog = () => {
        setHouseDialog({ ...houseDialog, open: false, data: [] });
    };

    //DIALOG - LOCATION
    const [locationDialog, setLocationDialog] = React.useState({
        open: false,
        data: {
            qrcodeWaze: '',
            qrcodeGoogle: '',
            linkWaze: '',
            linkGoogle: '',
            title: '',
            subtitle: '',
        }
    });

    const openLocationDialog = (qrWaze, qrGoogle, linkWaze, linkGoogle, title, subtitle) => {
        // setLocationDialog(true);
        setLocationDialog({ ...locationDialog, open: true, data: { qrcodeWaze: qrWaze, qrcodeGoogle: qrGoogle, linkWaze: linkWaze, linkGoogle, title: title, subtitle: subtitle } });
        closeHouseDialog()
    };

    const closeLocationDialog = () => {
        setLocationDialog({ ...locationDialog, open: false, data: [] });
        // setLocationDialog(false);
    };

    return (
        <>



            <div className='pos-relative' style={hideDoor ? { display: 'none' } : { zIndex: 9 }}>
                <div className={`pos-absolute w-full  ${enter && "slide-up-open"}`} style={{ zIndex: 2, height: '100vh', overflow: 'hidden', backgroundColor: '#F0F0F0', backgroundImage: 'url(/images/enter/texture.png)', backgroundSize: 'contain' }}>
                    <div className='pos-relative w-full h-full'>
                        <Container className=' h-full'>
                            <div className={`flex-c-m w-full ${styles.enterTitlePos}`}>
                                <div style={{ width: 70 }}>
                                    <img src='/images/enter/xixi.png' alt="xixi" className='w-full' />
                                </div>
                                <div style={{ width: 400, maxWidth: 'calc(100% - 140px)', padding: '0 5%' }}>
                                    <img src='/images/enter/title.png' alt="title" className='w-full' />
                                </div>
                                <div style={{ width: 70 }}>
                                    <img src='/images/enter/xixi.png' alt="xixi" className='w-full' />
                                </div>
                            </div>
                            {/* PHOTOS & WIREFOWRKS */}
                            <Hidden only='xs'>
                                <div className='w-full pos-relative flex-c-m p-t-30'>
                                    <div className='spin-animation pos-absolute' style={{ width: 250, maxWidth: '35%', left: '20%', bottom: '35%', minWidth: 150 }}>{/* marginRight: -200, marginTop: -120, */}
                                        <img src='/images/enter/firework.png' alt="title" className='w-full' />
                                    </div>
                                    <div className='spin-animation pos-absolute' style={{ width: 300, maxWidth: '40%', right: '20%', bottom: 0, minWidth: 150 }} >{/* marginTop: 100,  marginLeft: -200  */}
                                        <img src='/images/enter/firework.png' alt="title" className='w-full' />
                                    </div>
                                    <div className='pos-relative' style={{ zIndex: 2 }}>
                                        <div className='flex-m flex-col' style={{ width: 600, maxWidth: '70%', margin: '0 auto' }}>
                                            <div>
                                                <img src='/images/enter/groom.png' alt="title" className='w-full' />
                                            </div>
                                            <div className='pos-relative flex-c-m pointer' style={{ width: 350, maxWidth: '70%', margin: '-50px auto 0' }} onClick={() => { setMusicPlay(true); setEnter(true); }}>
                                                <img src='/images/enter/button.png' alt="title" className='w-full' />
                                                <p className='pos-absolute fs-17' style={{ color: '#F5CB7B', letterSpacing: 1 }}>{t('button.enter')}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Hidden>

                            <Hidden smUp>
                                <div className='spin-animation pos-absolute' style={{ width: 250, bottom: -120, right: -120, minWidth: 150 }}>
                                    <img src='/images/enter/firework.png' alt="title" className='w-full' />
                                </div>
                                <div className='pos-relative' >
                                    <div style={{ width: 600, maxWidth: '80%', margin: '0 auto' }}>
                                        <img src='/images/enter/groom.png' alt="title" className='w-full' />
                                    </div>
                                    <div className='flex-c-m pointer' style={{ width: 350, maxWidth: '70%', margin: '-50px auto 0' }} onClick={() => { setMusicPlay(true); setEnter(true); }}>
                                        <img src='/images/enter/button.png' alt="title" className='w-full' />
                                        <p className='pos-absolute fs-17' style={{ color: '#F5CB7B', letterSpacing: 1 }}>{t('button.enter')}</p>
                                    </div>
                                </div>
                            </Hidden>
                        </Container>
                        {/* <div className='pos-absolute flex-c-m w-full' style={{ bottom: '20vh' }}>
                        <div className='p-tb-15 p-lr-20 theme-bg clwhite txt-center txt-upper btn-open pointer' style={{ display: enter && 'none' }} >
                            <p>{t('button.enter')}</p>
                        </div>
                    </div> */}

                        {/* //LATTERN */}
                        <div className='pos-absolute lattern-animation' style={{ left: 20, top: -80, width: 100, maxWidth: '20%' }}>
                            <img src='/images/enter/lattern.png' alt="frame" />
                        </div>
                        <Hidden only='xs'>
                            <div className='pos-absolute lattern-animation' style={{ left: 60, top: 0, width: 100, maxWidth: '12%' }}>
                                <img src='/images/enter/lattern.png' alt="frame" />
                            </div>
                            <Hidden mdDown>
                                <div className='pos-absolute lattern-animation' style={{ right: 120, top: -80, width: 100, maxWidth: '12%' }}>
                                    <img src='/images/enter/lattern.png' alt="frame" />
                                </div>
                            </Hidden>
                            <div className='pos-absolute lattern-animation' style={{ right: 20, top: 0, width: 100, maxWidth: '12%' }}>
                                <img src='/images/enter/lattern.png' alt="frame" />
                            </div>
                        </Hidden>

                        {/* //FRAME */}
                        <Hidden only='xs'>
                            <div className='pos-absolute' style={{ left: 20, bottom: 20, width: 120 }}>
                                <img src='/images/enter/frame.png' alt="frame" />
                            </div>
                            <div className='pos-absolute' style={{ right: 20, bottom: 20, width: 120 }}>
                                <img src='/images/enter/frame.png' alt="frame" style={{ transform: 'scaleX(-1)' }} />
                            </div>
                        </Hidden>
                        <Hidden smUp>
                            <div className='pos-absolute' style={{ right: 20, top: 20, width: 70 }}>
                                <img src='/images/enter/frame.png' alt="frame" style={{ transform: 'scale(-1, -1)' }} />
                            </div>
                            <div className='pos-absolute' style={{ left: 20, bottom: 20, width: 70 }}>
                                <img src='/images/enter/frame.png' alt="frame" />
                            </div>
                        </Hidden>
                    </div>
                </div>
            </div>


            <div className={`translateY btn-float ${isVisible ? "pop-in" : "pop-in pop-out"}`} style={{ bottom: 38, right: 130 }} onClick={() => setMusicPlay(!musicPlay)} >
                {musicPlay ?
                    <FaPause className='fs-24' />
                    :
                    <FaPlay className='fs-24' />
                }
            </div>
            <div className={`translateY btn-float ${isVisible ? "pop-in" : "pop-in pop-out"}`} style={{ bottom: 90, right: 68 }} onClick={() => changeSystemLanguage(i18n.resolvedLanguage == 'en' ? 'cn' : 'en')}>
                {i18n.resolvedLanguage == 'en' ?
                    <p>中</p>
                    :
                    <p>EN</p>
                }
            </div>
            <div className="translateY btn-float" style={{ bottom: 20, right: 56, transform: 'scale(1)' }} onClick={handleToggle}>
                {isVisible ?
                    <FiX className='fs-24' />
                    :
                    <MdSettings className='fs-24' />
                }
            </div>


            <div style={hideDoor ? {} : { height: '100vh', overflow: 'hidden' }}>
                <Hidden only="xs">
                    <div>
                        <img src='/images/banner/banner1.png' alt="banner" />
                    </div>
                </Hidden>
                <Hidden smUp>
                    <div>
                        <img src='/images/banner/banner1-m.png' alt="banner" />
                    </div>
                </Hidden>
                {/* CountDown */}
                <div className='pos-relative'>
                    <Container maxWidth="md">

                        <div className='flex-c-m w-full' style={{ marginTop: -40 }}>
                            {
                                timeLeft.seconds > 0 ?
                                    <>
                                        <Hidden smUp>
                                            {
                                                timeLeft.days >= 1 &&
                                                <div>
                                                    <div className='countdown-box2'>{/* style={{padding:0}} */}
                                                        {/* <div className='w-full h-full p-tb-5 p-lr-25 flex-c-m pos-relative' style={{ background: 'linear-gradient(0deg, rgba(217,217,217,0) 32%, rgba(0,0,0,0.3) 48%, rgba(0,0,0,1) 50%, rgba(0,0,0,0.3) 52%, rgba(217,217,217,0) 66%)' }}> */}
                                                        <p>{timeLeft.days > 9 ? (timeLeft.days).toString().substring(0, 1) : 0}
                                                            {timeLeft.days < 10 ? (timeLeft.days).toString().substring(0, 1) : (timeLeft.days).toString().substring(1, 2)}</p>
                                                        {/* <div style={{width:4, height:14, left:0, background: '#848484', boxShadow: 'inset 0 2px 2px 0 #000'}} className='pos-absolute' />
                                                            <div style={{width:4, height:14, right:0, background: '#848484', boxShadow: 'inset 0 2px 2px 0 #000'}} className='pos-absolute' /> */}
                                                        {/* </div> */}
                                                    </div>
                                                    <p className='txt-center p-t-10 txt-upper'>{t('date.days')}</p>
                                                </div>
                                            }
                                            <div style={{ maxWidth: 30, width: '5vw' }}></div>
                                            <div>
                                                <div className='countdown-box2'>
                                                    <p>
                                                        {timeLeft.hours > 9 ? (timeLeft.hours).toString().substring(0, 1) : 0}
                                                        {timeLeft.hours < 10 ? (timeLeft.hours).toString().substring(0, 1) : (timeLeft.hours).toString().substring(1, 2)}
                                                    </p>
                                                </div>
                                                <p className='txt-center p-t-10 txt-upper'>{t('date.hours')}</p>
                                            </div>
                                            <div style={{ maxWidth: 30, width: '5vw' }}></div>
                                            <div>
                                                <div className='countdown-box2'>
                                                    <p>
                                                        {timeLeft.minutes > 9 ? (timeLeft.minutes).toString().substring(0, 1) : 0}
                                                        {timeLeft.minutes < 10 ? (timeLeft.minutes).toString().substring(0, 1) : (timeLeft.minutes).toString().substring(1, 2)}
                                                    </p>
                                                </div>
                                                <p className='txt-center p-t-10 txt-upper'>{t('date.minute')}</p>
                                            </div>
                                            <div style={{ maxWidth: 30, width: '5vw' }}></div>
                                            {
                                                timeLeft.days < 1 &&
                                                <div>
                                                    <div className='countdown-box2'>
                                                        <p>
                                                            {timeLeft.seconds > 9 ? (timeLeft.seconds).toString().substring(0, 1) : 0}
                                                            {timeLeft.seconds < 10 ? (timeLeft.seconds).toString().substring(0, 1) : (timeLeft.seconds).toString().substring(1, 2)}
                                                        </p>
                                                    </div>
                                                    <p className='txt-center p-t-10 txt-upper'>{t('date.second')}</p>
                                                </div>
                                            }
                                        </Hidden>

                                        <Hidden only="xs">
                                            {
                                                timeLeft.days >= 1 &&
                                                <div>
                                                    <div className='flex-c-m'>
                                                        <div className='countdown-box m-r-10'>
                                                            {timeLeft.days > 9 ? (timeLeft.days).toString().substring(0, 1) : 0}
                                                        </div>
                                                        <div className='countdown-box'>
                                                            {timeLeft.days < 10 ? (timeLeft.days).toString().substring(0, 1) : (timeLeft.days).toString().substring(1, 2)}
                                                        </div>
                                                    </div>
                                                    <p className='txt-center p-t-10 txt-upper'>{t('date.days')}</p>
                                                </div>
                                            }
                                            <div style={{ maxWidth: 30, width: '5vw' }}></div>
                                            <div>
                                                <div className='flex-c-m'>
                                                    <div className='countdown-box m-r-10'>
                                                        {timeLeft.hours > 9 ? (timeLeft.hours).toString().substring(0, 1) : 0}
                                                    </div>
                                                    <div className='countdown-box'>
                                                        {timeLeft.hours < 10 ? (timeLeft.hours).toString().substring(0, 1) : (timeLeft.hours).toString().substring(1, 2)}
                                                    </div>
                                                </div>
                                                <p className='txt-center p-t-10 txt-upper'>{t('date.hours')}</p>
                                            </div>
                                            <div style={{ maxWidth: 30, width: '5vw' }}></div>
                                            <div>
                                                <div className='flex-c-m'>
                                                    <div className='countdown-box m-r-10'>
                                                        {timeLeft.minutes > 9 ? (timeLeft.minutes).toString().substring(0, 1) : 0}
                                                    </div>
                                                    <div className='countdown-box'>
                                                        {timeLeft.minutes < 10 ? (timeLeft.minutes).toString().substring(0, 1) : (timeLeft.minutes).toString().substring(1, 2)}
                                                    </div>
                                                </div>
                                                <p className='txt-center p-t-10 txt-upper'>{t('date.minute')}</p>
                                            </div>
                                            <div style={{ maxWidth: 30, width: '5vw' }}></div>
                                            {
                                                timeLeft.days < 1 &&
                                                <div>
                                                    <div className='flex-c-m'>
                                                        <div className='countdown-box m-r-10'>
                                                            {timeLeft.seconds > 9 ? (timeLeft.seconds).toString().substring(0, 1) : 0}
                                                        </div>
                                                        <div className='countdown-box'>
                                                            {timeLeft.seconds < 10 ? (timeLeft.seconds).toString().substring(0, 1) : (timeLeft.seconds).toString().substring(1, 2)}
                                                        </div>
                                                    </div>
                                                    <p className='txt-center p-t-10 txt-upper'>{t('date.second')}</p>
                                                </div>
                                            }
                                        </Hidden>
                                    </>
                                    :
                                    null  /* TODO:: done countdown here, show anniversary date?????? */
                            }

                        </div>
                    </Container>
                    <Hidden mdDown>
                        <div className='pos-absolute lattern-animation' style={{ width: 80, top: 40, left: 120, zIndex: 3 }} >
                            <img src='/images/enter/lattern.png' alt='lattern' />
                        </div>
                    </Hidden>
                    <Hidden smUp >
                        <div className='pos-absolute lattern-animation' style={hideDoor ? { width: 80, top: 190, right: 30, zIndex: 3 } : { display: 'none' }}>
                            <img src='/images/enter/lattern-short.png' alt='lattern' />
                        </div>
                    </Hidden>

                </div>
                {/* Event Details */}
                <Container maxWidth="md" className='p-t-80 pos-relative' >
                    <Grid container>
                        <Grid item xs={12} sm={6} md={5}>
                            <img src='/images/landing/details.png' alt="details" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={7}>
                            <Hidden smUp>
                                <div className={`theme-bg spin-animation m-l-20 ${styles.squareBox}`} style={{ transform: 'rotate(25deg)', transformOrigin: 'center' }}></div>
                            </Hidden>
                            <div style={{ width: 400, maxWidth: '100%', margin: '0 auto' }}>
                                <div className='p-lr-30' style={{ width: 297, margin: '0 auto', maxWidth: '100%' }}>
                                    <img src='/images/title/schedule.png' alt="details" className='w-full' />
                                </div>
                                <div className='p-lr-30 p-t-30'>
                                    {/* // Pre-Wedding */}
                                    <div className='p-lr-5 m-b-20 p-tb-5 txt-center gold-color w-full flex-sb-m translateX pointer theme-bg' style={{ height: 50 }} onClick={() => openHouseDialog('/images/title/prewedding.png', t('date.dayDate13'), '7:00pm - 10:30pm')}>
                                        <div className='h-full'>
                                            <img src='/images/design/btn-frame.png' alt="btn" className='h-full' />
                                        </div>
                                        <p>{t('button.prewedding')}</p>
                                        <div className='h-full'>
                                            <img src='/images/design/btn-frame.png' alt="btn" className='h-full' style={{ transform: 'scaleX(-1)' }} />
                                        </div>
                                    </div>
                                    {/* // Wedding */}
                                    <div className='p-lr-5 m-b-20 p-tb-5 txt-center gold-color w-full flex-sb-m translateX pointer theme-bg' style={{ height: 50 }} onClick={() => openHouseDialog('/images/title/welcoming.png', t('date.dayDate14'), '7:30am')}>
                                        <div className='h-full'>
                                            <img src='/images/design/btn-frame.png' alt="btn" className='h-full' />
                                        </div>
                                        <p>{t('button.welcoming')}</p>
                                        <div className='h-full'>
                                            <img src='/images/design/btn-frame.png' alt="btn" className='h-full' style={{ transform: 'scaleX(-1)' }} />
                                        </div>
                                    </div>
                                    {/* // Dinner */}
                                    <div className='p-lr-5 m-b-20 p-tb-5 txt-center gold-color w-full flex-sb-m translateX pointer theme-bg' style={{ height: 50 }} onClick={() => setDinnerDialog(true)}>
                                        <div className='h-full'>
                                            <img src='/images/design/btn-frame.png' alt="btn" className='h-full' />
                                        </div>
                                        <p>{t('button.weddingDinner')}</p>
                                        <div className='h-full'>
                                            <img src='/images/design/btn-frame.png' alt="btn" className='h-full' style={{ transform: 'scaleX(-1)' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Hidden mdDown>
                        <div className={`pos-absolute theme-bg spin-animation ${styles.squareBox}`} style={{ right: 50, top: 30, transform: 'rotate(25deg)', transformOrigin: 'center' }}></div>
                    </Hidden>

                </Container>

                {/* Story */}
                {/* TASK - Add Title */}
                <div className='pos-relative p-b-0' style={{ overflow: 'hidden' }}>
                    <Container maxWidth="md" className='p-t-80 pos-relative' style={{ zIndex: 2 }}>
                        <div className='p-lr-30' style={{ width: 350, margin: '0 auto', maxWidth: '100%' }}>
                            <img src='/images/title/married.png' alt="details" className='w-full' />
                        </div>
                        <div>
                            <div className='theme-bg clwhite p-lr-50 p-tb-20 pos-relative' style={{ width: 485, maxWidth: '80%', margin: '0 auto', borderRadius:'30px 40px 0 20px' }}>
                                <div>
                                    <p className='lh-14'>{t('story.groomWords')}</p>
                                    <p className='txt-right p-t-9'>Johnson</p>
                                </div>
                                <div className='bor50 p-all-5 pos-absolute bgwhite' style={{ height: 80, width: 80, right:-40, bottom:-20 }}>
                                    <div className='theme-bg bor50 w-full h-full'>
                                    <img src='/images/design/groom.png' alt="groom" />
                                    </div>
                                </div>
                                <div className='pos-absolute' style={{left:8, top:8, width:25}}>
                                    <img src='/images/design/hightlight-groom.png' alt="hightlight"/>
                                </div>
                            </div>

                            <div className='theme-bg clwhite p-lr-50 p-tb-20 pos-relative' style={{ width: 485, maxWidth: '80%', margin: '20px auto 0', borderRadius:'20px 40px 30px 0' }}>
                                <div>
                                    <p className='lh-14'>{t('story.brideWords')}</p>
                                    <p className='txt-right p-t-9'>Aeryn</p>
                                </div>
                                <div className='bor50 p-all-5 pos-absolute bgwhite' style={{ height: 80, width: 80, left:-40, bottom:-20 }}>
                                    <div className='theme-bg bor50 w-full h-full'>
                                    <img src='/images/design/bride.png' alt="groom" />
                                    </div>
                                </div>
                                <div className='pos-absolute' style={{left:8, top:8, width:25}}>
                                    <img src='/images/design/hightlight-groom.png' alt="hightlight"/>
                                </div>
                            </div>
                            
                        </div>
                        {/* <div>
                            <p>Aeryn:</p>
                            <p className=' lh-14'>{t('story.brideWords')}</p>
                        </div> */}
                        <Hidden mdDown>
                            <StoryDesktop />
                        </Hidden>
                        <Hidden mdUp>
                            <StoryMobile />
                        </Hidden>
                        <Hidden mdDown>
                            {/* stquare */}
                            <div className={`pos-absolute theme-bg spin-animation ${styles.squareBox}`} style={{ left: 0, top: 60 }}></div>
                            {/* star */}
                            <div className='pos-absolute spin-animation' style={{ left: 80, top: 150, width: 60 }}>
                                <img src='/images/enter/star.png' alt="star" />
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            {/* stquare */}
                            <div className={`pos-absolute theme-bg spin-animation ${styles.squareBox}`} style={{ right: 60, top: 30 }}></div>
                            {/* star */}
                        </Hidden>
                    </Container>

                    <Hidden mdDown>
                        <div className={`pos-absolute theme-bg spin-animation ${styles.squareBox}`} style={{ right: 180, bottom: 30 }}></div>
                        <div className='pos-absolute spin-animation' style={{ right: -100, top: 150, width: 250 }}>
                            <img src='/images/enter/firework.png' alt="star" />
                        </div>
                    </Hidden>
                </div>


                {/* Bride and Groom */}
                <div className={`flex-c-m w-full pos-relative ${styles.storyPaddingBottom} p-t-80`}>
                    <div className='w-full theme-bg'>
                        <div style={{ backgroundImage: 'url(../images/landing/texture.png)', height: 230, maxHeight: '30vw', backgroundSize: 'contain', backgroundRepeat: 'repeat' }}></div>
                    </div>
                    <Container maxWidth="md" className='pos-absolute'>
                        <div className='flex-c-m'>
                            <div style={{ width: 250, maxWidth: '35%', transform: 'rotate(343deg)' }}>
                                <img src='/images/landing/johnson.png' alt="Aeryn" style={{ boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.15)' }} className='translateY' />
                            </div>
                            <div style={{ width: 250, maxWidth: '35%', transform: 'rotate(12deg)' }}>
                                <img src='/images/landing/aeryn.png' alt="Aeryn" style={{ boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.15)' }} className='translateY' />
                            </div>
                        </div>
                    </Container>
                    <Hidden mdDown>
                        <div className='pos-absolute' style={{ top: 80, left: 80, }}>
                            <div className='pos-relative' style={{ width: 150, transform: 'rotate(-25deg)', transformOrigin: 'center', zIndex: 2 }}>
                                <img src='/images/design/xixi-gold.png' alt="xix" className='translateY' />
                            </div>
                            <div className='lattern-animation m-l-20' style={{ width: 80, marginTop: -50 }}>
                                <img src='/images/design/lattern-gold.png' alt="xix" />
                            </div>
                        </div>
                        <div>
                            <div className='lattern-animation m-l-20 pos-absolute' style={{ width: 80, top: 80, right: 80 }}>
                                <img src='/images/design/lattern-gold.png' alt="xix" />
                            </div>
                        </div>
                        <div className='pos-absolute' style={{ width: 150, marginTop: -50, right: 180, bottom: 0, transform: 'rotate(25deg)', transformOrigin: 'center', }}>
                            <img src='/images/design/xixi-gold.png' alt="xix" className='translateY' />
                        </div>
                    </Hidden>
                    <Hidden mdUp>
                        <div className='lattern-animation pos-absolute' style={{ width: 30, top: 80, left: 20 }}>
                            <img src='/images/design/lattern-gold.png' alt="xix" />
                        </div>
                        <div className='lattern-animation pos-absolute' style={{ width: 60, top: 150, right: 40 }}>
                            <img src='/images/design/xixi-gold.png' alt="xix" />
                        </div>
                    </Hidden>

                </div>

                {/* Gallery */}
                <div className='p-b-100 pos-relative'>
                    <Hidden smUp>
                        <div className='pos-relative p-t-0 p-b-30'>
                            <div style={{ width: '60%', margin: '0 auto' }}>
                                <img src='/images/design/wish3.png' alt="wish" className='w-full' />
                            </div>
                            <div className='pos-absolute spin-animation' style={{ width: 45, top: 10, left: 20 }}>
                                <img src='/images/enter/star.png' alt="star" className='w-full' />
                            </div>
                            <div className='pos-absolute spin-animation theme-bg' style={{ bottom: 20, right: 30 }}>
                                <div className={`${styles.squareBox}`}></div>
                            </div>
                        </div>
                    </Hidden>
                    <GalleryMobile />
                </div>

                {/* Calendar */}
                <div className='pos-relative' style={{ overflow: 'hidden' }} >
                    <Hidden mdDown>
                        <div className='pos-absolute spin-animation' style={{ top: 150, right: -100, width: 250 }}>
                            <img src='/images/enter/firework2.png' alt="Firework" />
                        </div>
                        <div className='pos-absolute spin-animation' style={{ bottom: 80, left: 150, width: 180 }}>
                            <img src='/images/enter/firework3.png' alt="Firework" />
                        </div>
                    </Hidden>
                    <Container className='pos-relative w-full' style={{ overflow: 'hidden' }}>
                        {/* <div className='txt-center inter-normal p-b-30 lh-12' style={{ width: 600, maxWidth: '90%', margin: '0 auto' }}>
                            <p className='fs-30'>{t('calendar.title')}</p>
                            <p className='p-t-10'>{t('calendar.subtitle')}</p>
                        </div> */}
                        <div className='p-lr-30' style={{ width: 350, margin: '0 auto', maxWidth: '100%' }}>
                            <img src='/images/title/invitation.png' alt="details" className='w-full' />
                        </div>
                        <div className='pos-relative w-full' style={{ overflow: 'hidden' }}>
                            <div className='p-all-20 clwhite theme-bg' style={{ borderRadius: 15, width: 500, maxWidth: '100%', margin: '0 auto' }}>
                                <div className='flex-c-m w-full clwhite fs-21 txt-upper'>
                                    <p>2024</p>
                                    <p className='p-lr-20'>September</p>
                                    <p>九月</p>
                                </div>
                                <p className='fs-100 p-t-30 p-b-20 txt-center'>14</p>
                                <p className='fs-21 txt-center p-b-20' style={{ letterSpacing: 2 }}>{t('calendar.suitableMarriage')}</p>
                                <div style={{ lineHeight: 0 }}>
                                    <img src='/images/landing/calendar-frame-top.png' alt='frame' />
                                </div>
                                <div className='w-full clwhite txt-center p-tb-20 p-lr-30' style={{ backgroundImage: 'url(/images/landing/calendar-frame-middle.png)', backgroundRepeat: 'repeat-Y', backgroundSize: 'contain', lineHeight: 0 }}>
                                    <p className='fs-21 txt-upper' style={{ marginTop: -20 }}>Saturday 星期六</p>
                                    <div className='flex-sb-m w-full p-tb-20'>
                                        <div>
                                            <p className='txt-upper'>Johnson</p>
                                            <p>杨俊斌</p>
                                        </div>
                                        <div style={{ width: 50 }}>
                                            <img src='/images/landing/xixi.png' alt='xixi' />
                                        </div>
                                        <div>
                                            <p className='txt-upper'>Aeryn</p>
                                            <p>袁丽敏</p>
                                        </div>
                                    </div>

                                    <div className='flex-sb-m w-full p-tb-20 flex-responsive' style={{ borderTop: '1px solid #fff' }}>
                                        <Box className='flex-m' sx={{ width: { xs: '100%', sm: '60%' } }}>
                                            <FaMapMarkerAlt className='fs-28' />
                                            <p className='p-l-8 txt-left fs-12 lh-12'><span className='fs-16'>怡保MH酒店</span>,<br /> MH Grand Ballroom, Level 5</p>
                                        </Box>
                                        <Box className='txt-left' sx={{ width: { xs: '100%', sm: '40%' }, paddingTop: { xs: '10px', sm: 0 } }}>
                                            <p className='fs-12'>{t('dinner.address')}</p>
                                        </Box>
                                    </div>

                                    <div className='w-full p-tb-20 fs-14' style={{ borderTop: '1px solid #fff' }}>
                                        <Hidden only="xs">
                                            <p>{t('calendar.wishingDesktop1')}</p>
                                            <p>{t('calendar.wishingDesktop2')}</p>
                                        </Hidden>
                                        <Hidden smUp>
                                            <div className='fs-13'>
                                                <p className='p-b-5'>{t('calendar.wishingMobile1')}</p>
                                                <p>{t('calendar.wishingMobile2')}</p>
                                            </div>
                                        </Hidden>
                                    </div>
                                </div>
                                <div className='theme-bg' style={{ lineHeight: 0, transform: 'scaleY(-1)', marginTop: -35, }}>
                                    <img src='/images/landing/calendar-frame-top.png' alt='frame' />
                                </div>
                                <p className='txt-center fs-14 p-t-20'>{t('calendar.beSeated')}</p>
                            </div>
                            <Hidden mdDown>
                                <div className='flex-sb-m w-full pos-absolute p-lr-50' style={{ top: 0 }}>
                                    <div className='lattern-animation' style={{ width: 80 }}>
                                        <img src='/images/design/wish1.png' alt="wish1" className='w-full' />
                                    </div>
                                    <div className='lattern-animation' style={{ width: 80 }}>
                                        <img src='/images/design/wish2.png' alt="wish1" className='w-full' />
                                    </div>
                                </div>
                            </Hidden>
                        </div>
                    </Container>
                </div>

                {/* Navigation */}
                <Hidden mdDown>
                    <div className='pos-relative' style={{ overflow: 'hidden' }}>
                        <Container maxWidth="md" className='p-t-100 pos-relative' style={{ zIndex: 2 }} >
                            <div className='p-lr-30' style={{ width: 297, margin: '0 auto', maxWidth: '100%' }}>
                                <img src='/images/title/details.png' alt="details" className='w-full' />
                            </div>
                            <div className='flex-sb-m w-full flex-responsive-md'>
                                <div style={{ width: 300, maxWidth: '100%', minWidth: 350 }}>
                                    <CustomTabPanel value={value} index={0}>
                                        <img src='/images/enter/groom.png' alt='photos' />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        <img src='/images/enter/groom.png' alt='photos' />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        <div>
                                            <img src='/images/location/hotel.png' alt='house' />
                                        </div>
                                    </CustomTabPanel>
                                </div>
                                <div className='w-full p-lr-30'>
                                    <div className="w-full p-lr-24">
                                        <Tabs value={value} onChange={handleChangeNavigate} aria-label="basic tabs example">
                                            <Tab label={t('button.preweddingShort')} {...a11yProps(0)} />
                                            <Tab label={t('button.welcomingShort')} {...a11yProps(1)} />
                                            <Tab label={t('button.weddingDinnerShort')} {...a11yProps(2)} />
                                        </Tabs>
                                    </div>
                                    <CustomTabPanel value={value} index={0} >
                                        <div style={{ minHeight: 150 }}>
                                            <p className='theme-color p-b-15 fs-15'>{t('date.sep13')} <span className='p-lr-10'>·</span> {t('date.friday')} <span className='p-lr-10'>·</span> {t('date.7pm')} <span className='p-lr-10'>·</span> {t('date.buffet')}</p>
                                            <div className='flex-sb-m p-b-25 w-full'>
                                                <div className='theme-color p-r-15 w-full'>
                                                    <p><b>{t('general.groomSide')}</b></p>
                                                    <p>{t('general.groomAddress')}</p>
                                                </div>
                                                <div className='flex-m' style={{ gap: 10, width: 100 }} >
                                                    <div className='btn-square theme-bg gold-color pointer' onClick={() => openLocationDialog('/images/location/johnson-waze.png', '/images/location/johnson-google.png', '', '', '/images/title/prewedding.png', '男方 - Johnson')}>
                                                        <IoQrCode />
                                                    </div>
                                                    <div className='btn-square theme-bg gold-color pointer' onClick={() => openLocationDialog('/images/location/waze.png', '/images/location/google.png', 'https://t.ly/wvcTq', 'https://t.ly/e26sX', '/images/title/prewedding.png', '男方 - Johnson')}>
                                                        <FaLink />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex-sb-m p-b-15 w-full'>
                                                <div className='theme-color p-r-15 w-full'>
                                                    <p><b>{t('general.brideSide')}</b></p>
                                                    <p>{t('general.brideAddress')}</p>
                                                </div>
                                                <div className='flex-m' style={{ gap: 10, width: 100 }}>
                                                    <div className='btn-square theme-bg gold-color pointer' onClick={() => openLocationDialog('/images/location/aeryn-waze.png', '/images/location/aeryn-google.png', '', '', '/images/title/prewedding.png', '女方 - Aeryn')}>
                                                        <IoQrCode />
                                                    </div>
                                                    <div className='btn-square theme-bg gold-color pointer' onClick={() => openLocationDialog('/images/location/waze.png', '/images/location/google.png', 'https://t.ly/mQQot', 'https://t.ly/xu8i6', '/images/title/prewedding.png', '女方 - Aeryn')}>
                                                        <FaLink />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1} >
                                        <div style={{ minHeight: 150 }}>
                                            <p className='theme-color p-b-15 fs-15'>{t('date.sep14')} <span className='p-lr-10'>·</span> {t('date.sat')}</p>
                                            <div className='flex-sb-m p-b-25 w-full'>
                                                <div className='theme-color p-r-15 w-full'>
                                                    <p><b>{t('general.groomSide')}</b></p>
                                                    <p>{t('general.groomAddress')}</p>
                                                </div>
                                                <div className='flex-m' style={{ gap: 10, width: 100 }} >
                                                    <div className='btn-square theme-bg gold-color pointer' onClick={() => openLocationDialog('/images/location/johnson-waze.png', '/images/location/johnson-google.png', '', '', '/images/title/welcoming.png', '男方 - Johnson')}>
                                                        <IoQrCode />
                                                    </div>
                                                    <div className='btn-square theme-bg gold-color pointer' onClick={() => openLocationDialog('/images/location/waze.png', '/images/location/google.png', 'https://t.ly/wvcTq', 'https://t.ly/e26sX', '/images/title/welcoming.png', '男方 - Johnson')}>
                                                        <FaLink />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex-sb-m p-b-15 w-full'>
                                                <div className='theme-color p-r-15 w-full'>
                                                    <p><b>{t('general.brideSide')}</b></p>
                                                    <p>{t('general.brideAddress')}</p>
                                                </div>
                                                <div className='flex-m' style={{ gap: 10, width: 100 }}>
                                                    <div className='btn-square theme-bg gold-color pointer' onClick={() => openLocationDialog('/images/location/aeryn-waze.png', '/images/location/aeryn-google.png', '', '', '/images/title/welcoming.png', '女方 - Aeryn')}>
                                                        <IoQrCode />
                                                    </div>
                                                    <div className='btn-square theme-bg gold-color pointer' onClick={() => openLocationDialog('/images/location/waze.png', '/images/location/google.png', 'https://t.ly/mQQot', 'https://t.ly/xu8i6', '/images/title/welcoming.png', '女方 - Aeryn')}>
                                                        <FaLink />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        <div style={{ minHeight: 150 }}>
                                            <div className='flex-sb-m w-full'>
                                                <div className='theme-color'>
                                                    <p className='fs-18 p-b-10'><b>{t('dinner.name')}</b></p>
                                                    <div className='theme-bg' style={{ height: 4, width: 80 }}></div>
                                                    <p className='p-t-15 lh-12'>PT212695B, Jalan Medan Ipoh 1A,<br />Medan Ipoh Bestari, 31400 Ipoh, Perak</p>
                                                </div>
                                                <Hidden mdDown>
                                                    <div className='flex-m' style={{ gap: 10, width: 100 }}>
                                                        <div className='btn-square theme-bg pointer' onClick={() => openLocationDialog('/images/location/hotel-waze.png', '/images/location/hotel-google.png', '', '', '/images/title/dinner.png', '')}>
                                                            <IoQrCode />
                                                        </div>
                                                        <div className='btn-square theme-bg pointer' onClick={() => openLocationDialog('/images/location/waze.png', '/images/location/google.png', 'https://t.ly/zW6rV', 'https://t.ly/D0X8o', '/images/title/dinner.png', '')}>
                                                            <FaLink />
                                                        </div>
                                                    </div>
                                                </Hidden>
                                            </div>
                                            <Hidden mdUp>
                                                <div className='flex-m w-full p-t-10' style={{ gap: 10 }}>
                                                    <div className='btn-square theme-bg pointer p-lr-20' style={{ width: 'unset' }} onClick={() => openLocationDialog('/images/location/hotel-waze.png', '/images/location/hotel-google.png', '', '', '/images/title/dinner.png', '')}>
                                                        <IoQrCode className='fs-20' />
                                                        <p className='p-l-10 fs-14'>QR Code</p>
                                                    </div>
                                                    <div className='btn-square theme-bg pointer p-lr-20' style={{ width: 'unset' }} onClick={() => openLocationDialog('/images/location/waze.png', '/images/location/google.png', 'https://t.ly/zW6rV', 'https://t.ly/D0X8o', '/images/title/dinner.png', '')}>
                                                        <FaLink className='fs-20' />
                                                        <p className='p-l-10 fs-14' >Direction Link</p>
                                                    </div>
                                                </div>
                                            </Hidden>
                                        </div>
                                    </CustomTabPanel>
                                </div>
                            </div>
                        </Container>

                        <div className='spin-animation pos-absolute' style={{ left: 160, width: 300, bottom: 80 }}>
                            <img src='/images/enter/firework3.png' alt="firework" className='w-full' />
                        </div>
                        <div className='spin-animation pos-absolute' style={{ right: -150, width: 300, top: 0 }}>
                            <img src='/images/enter/firework.png' alt="firework" className='w-full' />
                        </div>
                        <div className={`${styles.squareBox} theme-bg pos-absolute spin-animation`} style={{ top: 50, right: 300 }}></div>
                        <div className={`${styles.squareBox} theme-bg pos-absolute spin-animation`} style={{ bottom: -80, left: 150 }}></div>
                    </div>

                </Hidden>

                {/* HOUSE DIALOG */}
                <Dialog
                    open={houseDialog.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={closeHouseDialog}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogFrame>
                        <div className='w-full flex-c-m'>
                            <img src={houseDialog.data.title} alt="xixi" style={{ height: 55 }} />
                        </div>
                        <div className='p-t-30'>
                            <div className='flex-sb-m w-full p-b-5'>
                                <div>
                                    <p className='fs-17 txt-upper'><b>{t('general.groomJohnson')}</b></p>
                                </div>
                                <div className='flex-m' style={{ gap: 10 }} >
                                    <div className='btn-square-small gradient-red pointer' onClick={() => openLocationDialog('/images/location/johnson-waze.png', '/images/location/johnson-google.png', '', '', '/images/design/wish4.png', t('general.groomJohnson'))}>
                                        <IoQrCode />
                                    </div>
                                    <div className='btn-square-small gradient-red pointer' onClick={() => openLocationDialog('/images/location/waze.png', '/images/location/google.png', 'https://t.ly/wvcTq', 'https://t.ly/e26sX', '/images/design/wish4.png', t('general.groomJohnson'))}>
                                        <FaLink />
                                    </div>
                                </div>
                            </div>
                            <p className='fs-14 lh-12'>{t('general.groomAddress')}</p>
                            <div className='flex-m fs-14 p-t-5'>
                                <MdAccessTime className='fs-18' />
                                <p className='p-l-10'>{houseDialog.data.time}</p>
                            </div>
                            <div className='flex-m fs-14 p-t-5'>
                                <LuCalendarCheck className='fs-18' />
                                <p className='p-l-10'>{houseDialog.data.date}</p>
                            </div>
                        </div>
                        <div className='p-t-30'>
                            <div className='flex-sb-m w-full p-b-5'>
                                <p className='fs-17 txt-upper'><b>{t('general.brideAeryn')}</b></p>
                                <div className='flex-m' style={{ gap: 10 }} >
                                    <div className='btn-square-small gradient-red pointer' onClick={() => openLocationDialog('/images/location/aeryn-waze.png', '/images/location/aeryn-google.png', '', '', '/images/design/wish4.png', t('general.brideAeryn'))}>
                                        <IoQrCode />
                                    </div>
                                    <div className='btn-square-small gradient-red pointer' onClick={() => openLocationDialog('/images/location/waze.png', '/images/location/google.png', 'https://t.ly/mQQot', 'https://t.ly/xu8i6', '/images/design/wish4.png', t('general.brideAeryn'))}>
                                        <FaLink />
                                    </div>
                                </div>
                            </div>
                            <p className='fs-14 p-b-5 lh-12'>{t('general.brideAddress')}</p>
                            <div className='flex-m fs-14 p-t-5'>
                                <MdAccessTime className='fs-18' />
                                <p className='p-l-10'>{houseDialog.data.time}</p>
                            </div>
                            <div className='flex-m fs-14 p-t-5'>
                                <LuCalendarCheck className='fs-18' />
                                <p className='p-l-10'>{houseDialog.data.date}</p>
                            </div>
                        </div>
                    </DialogFrame>
                </Dialog>

                {/* DINNER DIALOG */}
                <Dialog
                    open={dinnerDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClick={() => setDinnerDialog(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogFrame>
                        <div className='w-full flex-c-m'>
                            <img src="/images/title/dinner.png" alt="xixi" style={{ height: 55 }} />
                        </div>
                        <div className='p-t-30'>
                            <div className='flex-sb-m w-full p-b-5'>
                                <p className='fs-18'><b>{t('dinner.name')}</b></p>
                                <div className='flex-m' style={{ gap: 10 }} >
                                    <div className='btn-square-small gradient-red pointer' onClick={() => openLocationDialog('/images/location/hotel-waze.png', '/images/location/hotel-google.png', '', '', '/images/title/dinner.png', t('dinner.name'))}>
                                        <IoQrCode />
                                    </div>
                                    <div className='btn-square-small gradient-red pointer' onClick={() => openLocationDialog('/images/location/waze.png', '/images/location/google.png', 'https://t.ly/n3HID', 'https://t.ly/-bXhV', '/images/title/dinner.png', t('dinner.name'))}>
                                        <FaLink />
                                    </div>
                                </div>
                            </div>
                            <p className='fs-14 lh-12 p-b-10'>{t('dinner.address')}</p>
                            <div className='flex-m fs-14 p-t-5'>
                                <MdAccessTime className='fs-18' />
                                <p className='p-l-10'>7pm - 10:30pm</p>
                            </div>
                            <div className='flex-m fs-14 p-t-5'>
                                <LuCalendarCheck className='fs-18' />
                                <p className='p-l-10'>14 - 09 - 2024 ({t('date.sat')})</p>
                            </div>
                        </div>

                    </DialogFrame>
                </Dialog>

                {/* LOCATION - DIALOG */}
                <Dialog
                    open={locationDialog.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={closeLocationDialog}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogFrame>
                        <div className='flex-c-m w-full'>
                            <img src={locationDialog.data.title} alt="title" style={{ height: 55 }} />
                        </div>

                        <p className='fs-16 txt-center p-t-25'><b>{locationDialog.data.subtitle}</b></p>
                        <div className='flex-c-m p-t-15' style={{ gap: 40 }}>
                            <a href={locationDialog.data.linkWaze} target="_blank" rel="noreferrer" className='cl-label'>
                                <div className='txt-center flex-c-m flex-col translateY'>
                                    <div style={{ width: 100, height: 100, background: '#33CCFF' }} className='bor5 flex-c-m'>
                                        <img src={locationDialog.data.qrcodeWaze} alt='waze' className='bor5' />
                                    </div>
                                    <p className='cl-label txt-upper p-t-10 fs-13'>Waze</p>
                                </div>
                            </a>

                            <a href={locationDialog.data.linkGoogle} target="_blank" rel="noreferrer" className='cl-label'>
                                <div className='txt-center flex-c-m flex-col translateY'>
                                    <div style={{ width: 100, height: 100, background: '#fff' }} className='bor5 flex-c-m'>
                                        <img src={locationDialog.data.qrcodeGoogle} alt='waze' className='bor5' />
                                    </div>
                                    <p className='cl-label txt-upper p-t-10 fs-13'>Google Map</p>
                                </div>
                            </a>
                        </div>
                    </DialogFrame>
                </Dialog>

                {/* RSVP */}
                <div className='pos-relative' style={{ overflow: 'hidden' }}>
                    <Container maxWidth="md" className='p-t-100 p-b-80 ' >
                        <div className='p-lr-30' style={{ width: 350, margin: '0 auto', maxWidth: '100%' }}>
                            <img src='/images/title/testimony.png' alt="details" className='w-full' />
                        </div>
                        <Hidden mdUp>
                            <div className='p-all-20' style={{ width: 300, margin: '0 auto', maxWidth: '100%' }}>
                                <img src='/images/landing/photo-rsvp.png' alt="contact" />
                            </div>
                        </Hidden>
                        <div className='flex-sa-m flex-responsive'>
                            <Hidden mdDown>
                                <div style={{ width: 300, maxWidth: '100%' }}>
                                    <img src='/images/landing/photo-rsvp.png' alt="contact" />
                                </div>
                            </Hidden>
                            <Box component="form" onSubmit={submitData} autoComplete="off" style={{ width: 400, maxWidth: '100%', margin: '0 auto' }} sx={{ padding: { xs: '20px 20px 0', sm: 0 } }}>
                                <div className='m-b-20'>
                                    <TextField
                                        className='textfield'
                                        InputProps={{ disableUnderline: true }}
                                        label={t('rsvp.name')}
                                        variant="filled"
                                        fullWidth
                                        name="name"
                                        value={state.name}
                                        // InputLabelProps={{ shrink: true }}
                                        error={inputErrors && inputErrors.name ? true : false}
                                        onChange={handleChange}
                                    />
                                    {
                                        inputErrors && inputErrors?.name &&
                                        <p className='fs-12 p-t-8 theme-color'>{inputErrors.name}</p>
                                    }
                                </div>
                                <div className='m-b-20'>
                                    <TextField
                                     className='textfield'
                                        InputProps={{ disableUnderline: true }}
                                        label={t('rsvp.pax')}
                                        variant="filled"
                                        fullWidth
                                        name="pax"
                                        value={state.pax}
                                        // InputLabelProps={{ shrink: true }}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        // helperText={inputErrors && inputErrors.pax ? inputErrors.pax : ''}
                                        error={inputErrors && inputErrors.pax ? true : false}
                                        onChange={handleChange}
                                    />
                                    {
                                        inputErrors && inputErrors?.pax &&
                                        <p className='fs-12 p-t-8 theme-color'>{inputErrors.pax}</p>
                                    }
                                </div>
                                {/* Mobile */}
                                <div className='m-b-20'>
                                    <TextField
                                        className='textfield'
                                        InputProps={{ disableUnderline: true }}
                                        label={t('rsvp.mobile')}
                                        variant="filled"
                                        fullWidth
                                        name="mobile"
                                        value={state.mobile}
                                        // InputLabelProps={{ shrink: true }}
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        // helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                        error={inputErrors && inputErrors.mobile ? true : false}
                                        onChange={handleChange}
                                    />
                                    {
                                        inputErrors && inputErrors?.mobile &&
                                        <p className='fs-12 p-t-8 theme-color'>{inputErrors.mobile}</p>
                                    }
                                </div>

                                {/* Relationship */}
                                <div className='flex-sb-m flex-responsive-md'>
                                    {/* -- Relationship To -- */}
                                    <div className={`m-b-20 ${styles.textFieldWidth}`}>
                                        <FormControl className='textfield' fullWidth error={inputErrors && inputErrors.relationship_to ? true : false}>
                                            <Select
                                                id="relationship_to"
                                                value={state.relationship_to || 0}
                                                name="relationship_to"
                                                onChange={handleChange}
                                            >
                                                <MenuItem key={0} value={0} style={{ color: '#9CA3AF', opacity:1 }} disabled>--- {`${t('rsvp.relationshipTo')}`} --- </MenuItem>
                                                {
                                                    _.map(RELATIONSHIP_TO, (value, key) => {
                                                        return (
                                                            <MenuItem key={key} value={key}>
                                                                <p className='p-r-5'>{value}</p>
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        {
                                            inputErrors && inputErrors?.relationship_to &&
                                            <p className='fs-12 p-t-8 theme-color' style={{minHeight:36}}>{inputErrors?.relationship_to}</p>
                                        }
                                    </div>
                                    {/* -- Relationship -- */}
                                    <div className={`m-b-20 ${styles.textFieldWidth}`}>
                                        {/* <p>{t('rsvp.relationship')}</p> */}
                                        <FormControl className='textfield' fullWidth error={inputErrors && inputErrors.relationship ? true : false}>
                                            <Select
                                                id="relationship"
                                                value={state.relationship || 0}
                                                name="relationship"
                                                onChange={handleChange}
                                            >
                                                <MenuItem key={0} value={0} style={{ color: '#9CA3AF', opacity:1 }} disabled>--- {`${t('rsvp.relationship')}`} --- </MenuItem>
                                                {
                                                    _.map(RELATIONSHIP, (value, key) => {
                                                        return (
                                                            <MenuItem key={key} value={key}>
                                                                <p className='p-r-5'>{value}</p>
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        {
                                            inputErrors && inputErrors?.relationship &&
                                            <p className='fs-12 p-t-8 theme-color' style={{minHeight:36}}>{inputErrors?.relationship}</p>
                                        }
                                    </div>
                                </div>
                                
                               {/* Remark */}
                                <div className='m-b-20'>
                                    <TextField InputProps={{ disableUnderline: true }}
                                        label={t('rsvp.remark')}
                                        className='textfield'
                                        variant="filled"
                                        fullWidth
                                        name="remark"
                                        value={state.remark}
                                        // InputLabelProps={{ shrink: true }}
                                        // helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                                        error={inputErrors && inputErrors.remark ? true : false}
                                        onChange={handleChange}
                                    />
                                    {
                                        inputErrors && inputErrors?.remark &&
                                        <p className='fs-12 p-t-8 theme-color'>{inputErrors.remark}</p>
                                    }
                                </div>

                                <Hidden mdDown>
                                    <Button type="submit" className='theme-bg' style={{ padding: '15px 20px', background: '#AF3535', boxShadow: '-4px 4px 4px 0px rgba(179, 117, 117, 0.14), 6px -6px 12px 0px #FFF', borderRadius: 10, width: 'fit-content', minWidth: 150 }}>
                                        <p className='fs-13 clwhite txt-title'>{t('rsvp.submit')}</p>
                                    </Button>
                                </Hidden>
                                <Hidden mdUp>
                                    <Button type="submit" className='theme-bg' fullWidth style={{ padding: '15px 20px', background: '#AF3535', boxShadow: '-4px 4px 4px 0px rgba(179, 117, 117, 0.14), 6px -6px 12px 0px #FFF', borderRadius: 10, }}>
                                        <p className='fs-13 clwhite txt-title'>{t('rsvp.submit')}</p>
                                    </Button>
                                </Hidden>
                            </Box>
                        </div>
                    </Container>
                    <Hidden mdDown>

                        <div className='pos-absolute lattern-animation' style={{ width: 100, maxWidth: '12%', right: 130, top: 130 }}>
                            <img src="/images/enter/lattern.png" alt="lattern" className='w-full' />
                        </div>
                        <div className='pos-absolute lattern-animation' style={{ width: 100, maxWidth: '12%', right: 200, top: 50, }}>
                            <img src="/images/enter/lattern-short.png" alt="lattern" className='w-full' />
                        </div>
                    </Hidden>

                    <Hidden mdUp>
                        <div className='pos-absolute spin-animation' style={{ width: 230, maxWidth: '40%', right: -100, top: 30 }}>
                            <img src="/images/enter/firework3.png" alt="firework3" className='w-full' />
                        </div>
                    </Hidden>
                </div>
            </div>
        </>
    )
});

const useStyles = makeStyles((theme) => ({
    enterTitlePos: {
        paddingTop: '8%',
        // [theme.breakpoints.up('sm')]: {
        //     top: '-1vw'
        // },
        [theme.breakpoints.down('md')]: {
            paddingTop: '8vh',
            paddingBottom: '1vh'
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '38%',
            paddingBottom: '5%'
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '35%',
            paddingBottom: '5%'
        },
    },
    squareBox: {
        height: 35,
        width: 35,
        [theme.breakpoints.down('sm')]: {
            height: 20,
            width: 20,
        },
    },
    storyPaddingBottom: {
        paddingBottom: 100,
        [theme.breakpoints.down('md')]: {
            paddingBottom: 100
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 80
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 20
        },
    },
    textFieldWidth:{
        width: '47%',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
    },
}));

export default Home;